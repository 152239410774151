.form-control {
  border-radius: 10px;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.75rem;
  line-height: 1.5;
  border: 1px solid #8e8b8b !important;
  background: #15151a !important;
  color: #ffffff !important;
  height: initial;
  font-family: Montserrat, Arial, sans-serif;
}

.form-control.is-invalid {
  background: #d1d1d1;
}

.login-footer {
  margin-bottom: 10px;
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 99%;
  font-size: 0.9em;
  color: #868686;
  margin-left: -12px;
}

.action-button:hover {
  background-color: #434343 !important;
}

.login-cont {
  padding: 40px;
  display: flex;
  align-items: center;
  background: #15151a;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 15px;
}
