@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

*::-webkit-scrollbar-thumb {
  background: #292932;
  border: 0 none #292932;
  border-radius: 50px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #8e8b8b;
}

*::-webkit-scrollbar-thumb:active {
  background: #292932;
}

*::-webkit-scrollbar-track {
  border: 0 none #ffffff;
  border-radius: 50px;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}
